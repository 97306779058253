import React from 'react';

const Answers = ({ answers, selectedAnswer, handleAnswerSelect, result, answersDisabled }) => (
    <div className="mb-6">
        {Object.entries(answers).map(([key, answer], index) => (
            <button
                key={index}
                type="button"
                onClick={() => handleAnswerSelect(key)}
                className={`block w-full text-left py-2 px-4 mb-2 rounded-lg ${getButtonClass(key, result, selectedAnswer)}`}
                disabled={answersDisabled}
            >
                {answer}
            </button>
        ))}
    </div>
);

const getButtonClass = (key, result, selectedAnswer) => {
    if (result) {
        if (selectedAnswer === key) {
            return result.correct ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
        } else if (result.correct_answer === key) {
            return 'bg-green-500 text-white';
        }
    }
    return 'bg-gray-800 text-gray-200 hover:bg-gray-700';
};

export default Answers;
